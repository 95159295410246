<div dougsModalTitle>
  <h6>
    <i class="fal fa-receipt mr-4"></i>
    Configurer la tâche
  </h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <div class="configure-task">
    <dougs-task-form-fields
      (cancelTaskForm)="cancelTaskConfig()"
      (submitTaskForm)="updateTaskConfig()"
      [taskFormInlineGroup]="data.form?.modalGroup"
      [task]="data.task"
    ></dougs-task-form-fields>
  </div>
</div>
