import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MODAL_DATA, ModalCloseDirective, ModalContentDirective, ModalRef, ModalTitleDirective } from '@dougs/ds';
import { Task, TaskForm } from '@dougs/task/dto';
import { TemplateTaskStateService } from '@dougs/task/shared';
import { TaskFormFieldsComponent } from '../../components/control-panel/task/task-form-fields/task-form-fields.component';

/** @deprecated */
@Component({
  selector: 'dougs-configure-task-modal',
  templateUrl: './configure-task-modal.component.html',
  styleUrls: ['./configure-task-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, TaskFormFieldsComponent],
})
export class ConfigureTaskModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      form: TaskForm | null;
      task: Task;
    },
    private readonly templateTaskStateService: TemplateTaskStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef<TaskForm | null, { form: TaskForm; task: Task }>,
  ) {}

  async updateTaskConfig(): Promise<void> {
    this.modalRef.close(this.data.form);
  }

  async cancelTaskConfig(): Promise<void> {
    this.data.form = await this.templateTaskStateService.getTaskConfiguration(this.data.task);
    this.cdr.markForCheck();
  }
}
